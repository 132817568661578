import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../../components/layout"
import ImageGalleryWithLightbox from "../../components/imageGalleryWithLightbox"

const GedaechtnisTraining = ({data}) => {

  // Single Image Data

  const allImages = data.allImages.edges
  const memoryImage = (data.allImages.edges.pop()).node.childImageSharp.fluid

  const title = "Gedächtnistraining"

  return (
    <Layout title={title} slogan="" headerImage={data.allImages.edges[0].node.childImageSharp.fluid}>
      <div className="grid grid-2" style={{gridTemplateColumns: '2fr 1fr'}}>
        <div>
          <h4>Definition Gedächtnistraining</h4>
          <p>
          Gedächtnistraining ist ein Sammelbegriff für Betreuungsmassnahmen mit dem Ziel, auf spielerische Weise kognitive Fähigkeiten zu erhalten und zu fördern.
          </p>
          <h4>Ziel des Gedächtnistrainings</h4>
          <p>Der spielerische Faktor hat Vorrang vor therapeutischen Zielen. Das Gedächtnistraining soll unseren Bewohnern Spass machen und nicht zur Pflicht werden. Die Teilnahme am Gedächtnistraining ist freiwillig.
            <br/>Ziel ist das Trainieren der Gehirnleistung d.h:</p>
          <ul>
            <li>Konzentrationsf&auml;higkeit</li>
            <li>Wahrnehmungsverm&ouml;gen</li>
            <li>Merkf&auml;higkeit</li>
            <li>Wortfindungsf&auml;higkeit</li>
            <li>Entscheidungsf&auml;higkeit</li>
            <li>Assoziatives Denken</li>
            <li>Denken in Zusammenh&auml;ngen</li>
          </ul>
          <h4>Gruppen</h4>
          <p>Das Gedächtnistraining findet in kleinen Gruppen regelmässig statt (max. 4-5 Personen). Die Gruppen sind unter Berücksichtigung der kognitiven Fähigkeiten der Bewohner und Bewohnerinnen zusammengesetzt.</p>
          <ImageGalleryWithLightbox className="grid grid-3" images={allImages} />
        </div>
        <div>
          <Image fluid={memoryImage} />
          <h4 style={{marginTop: '2rem'}}>Angebot</h4>
          <ul>
            <li>Merkspiele: Memory-Spiel</li>
            <li>Zahlenrätsel: Sudoku</li>
            <li>Kreuzworträtsel</li>
            <li>Bilderrätsel</li>
            <li>Fehlersuchbilder</li>
            <li>Worte im Text finden</li>
            <li>Erraten und Vervollständigen von Sprichwörtern</li>
            <li>Labyrinth</li>
            <li>Brettspiele</li>
            <li>Quizfragen</li>
            <li>Und vieles mehr…</li>
            </ul>
        </div>
      </div>
    </Layout>
  ) 
}

export default GedaechtnisTraining

export const pageQuery = graphql`
  query {
    allImages: allFile(filter: {relativeDirectory: {eq: "angebot/gedaechtnistraining"}}, sort: {order: ASC, fields: name}) {
			edges {
				node {
					childImageSharp {
						fluid(quality: 90, maxWidth: 1600) {
							...GatsbyImageSharpFluid_withWebp
						}
					},
				}
			}
		}
  }
`